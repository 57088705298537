<template>
  <v-card>
    <v-toolbar color="grey darken-3" dark elevation="1">
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y v-model="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              :disabled="isNew"
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text class="pt-4">
      <v-form v-model="formValid" ref="form">
        <v-row>
          <v-col cols="12" md="12">
            <AddressElementSelect v-model="cityId" req
                                  label="Населений пункт" filled
                                  select_type="city"
            />
          </v-col>
          <v-col cols="12" md="12">
            <AddressElementSelect v-model="cityAreaId"
                                  label="Район міста" filled
                                  :parent_id="cityId" :use_parent_id="true"
                                  select_type="city-area"
            />
          </v-col>
          <v-col cols="4" md="3">
            <v-select :items="streetTypes" hide-details filled label="Тип" v-model="streetType"
                      required :rules="[v => !!v || 'Це поле є обов’язковим']"
                      :class="streetType ? '' : 'req-star'"
                      color="grey"
            />
          </v-col>
          <v-col cols="8" md="9">
            <v-text-field type="text" hide-details filled label="Вулиця" v-model="streetName"
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="streetName ? '' : 'req-star'"
                          color="grey"
            />
          </v-col>
          <v-col cols="12" md="3" sm="12">
            <v-text-field type="text" hide-details filled label="Приватних буд."
                          v-model="countBuildings" color="grey" v-integer
            />
          </v-col>
          <v-col cols="12" md="4" sm="6">
            <v-text-field type="text" hide-details filled label="Фактично будинків"
                          v-model="countBuildingsFact" color="grey" v-integer
            />
          </v-col>
          <v-col cols="12" md="5" sm="6">
            <v-text-field type="text" hide-details filled label="Багатокварт. будинків>"
                          v-model="countBuildingsHighRise" color="grey" v-integer
            />
          </v-col>
          <v-col cols="12" md="3" sm="6">
            <v-text-field type="text" hide-details filled label="Код" v-model="streetCode" color="grey"
            />
          </v-col>
          <v-col cols="12" md="3" sm="6">
            <v-text-field type="text" hide-details filled label="Для обміну №1" v-model="bankCode1" color="grey"
            />
          </v-col>
          <v-col cols="12" md="3" sm="6">
            <v-text-field type="text" hide-details filled label="Для обміну №2" v-model="bankCode2" color="grey"
            />
          </v-col>
          <v-col cols="12" md="3" sm="6">
            <v-text-field type="text" hide-details filled label="Для обміну №3" v-model="bankCode3" color="grey"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed text tile @click.stop="createCity" :loading="getModalLoading"
             class="button-accept">
        <v-icon left>mdi-content-save</v-icon>
        Зберегти
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {streetTypes_select} from "@/utils/icons";
import {CREATE_STREET, DELETE_STREET, UPDATE_STREET} from "@/store/actions/city";
import {mapGetters} from 'vuex'
import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";

import ModalComponentMixin from "@/mixins/modal_component";

const modalDeleteId = 'street_modal_delete'

export default {
  name: "HWP_Modal_Street",
  mixins: [ModalComponentMixin],
  components: {
    AddressElementSelect: () => import('@/components/autocomplite/AddressElementSelect'),
  },
  data() {
    return {
      streetTypes: streetTypes_select,
      streetId: this.item.id,
      streetType: this.item.street_type || '',
      streetName: this.item.name || '',
      streetCode: this.item.code || '',
      cityAreaId: this.item.city_area_id,
      cityId: this.item.city_id || 0,
      bankCode1: this.item.bank_code_1,
      bankCode2: this.item.bank_code_2,
      bankCode3: this.item.bank_code_3,
      countBuildings: this.item.count_buildings,
      countBuildingsFact: this.item.count_buildings_fact,
      countBuildingsHighRise: this.item.count_buildings_high_rise
    }
  },
  methods: {
    closeModal(payload=null) {
      this.$emit('closeModal', payload)
      this.streetId = this.item.id
      this.streetType = this.item.street_type
      this.streetName = this.item.name
      this.streetCode = this.item.code
      this.cityId = this.item.city_id
      this.cityAreaId = this.item.city_area_id
      this.bankCode1 = this.item.bank_code_1
      this.bankCode2 = this.item.bank_code_2
      this.bankCode3 = this.item.bank_code_3
      this.countBuildings = this.item.count_buildings
      this.countBuildingsFact = this.item.count_buildings_fact
      this.countBuildingsHighRise = this.item.count_buildings_high_rise

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    createCity() {
      this.formValid = this.$refs.form.validate()
      if (!this.formValid) {
        this.formValid = false
        this.$refs.form.validate()
        this.$store.commit(ALERT_SHOW, {
          text: 'Населений пункт, тип та назва вулиці - мають бути заповнені',
          color: 'error lighten-1'
        })
        return
      }

      const payload = {
        name: this.streetName,
        code: this.streetCode,
        city_id: this.cityId,
        street_type: this.streetType,
        city_area_id: this.cityAreaId,
        bank_code_1: this.bankCode1,
        bank_code_2: this.bankCode2,
        bank_code_3: this.bankCode3,
        count_buildings: this.countBuildings,
        count_buildings_fact: this.countBuildingsFact,
        count_buildings_high_rise: this.countBuildingsHighRise
      }

      if (this.isNew) {
        this.$store.dispatch(CREATE_STREET, payload)
          .then(r => {
            if (r) {
              this.closeModal({fetch: true, emit: true})
            }
          })
      } else {
        payload.id = this.streetId
        this.$store.dispatch(UPDATE_STREET, payload)
            .then(r => {
              if (r) {
                this.closeModal({fetch: true, emit: true})
              }
            })
      }
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення вулиці: ${this.streetName}`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.streetId = payload.id
              this.streetType = payload.street_type
              this.streetName = payload.name
              this.streetCode = payload.code
              this.cityId = payload.city_id
              this.cityAreaId = payload.city_area_id
              this.bankCode1 = payload.bank_code_1
              this.bankCode2 = payload.bank_code_2
              this.bankCode3 = payload.bank_code_3
              this.countBuildings = payload.count_buildings
              this.countBuildingsFact = payload.count_buildings_fact
              this.countBuildingsHighRise = payload.count_buildings_high_rise
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(DELETE_STREET, this.streetId)
                      .then(ok => {
                        if (ok) {
                          this.closeModal({fetch: true, emit: true})
                        }
                      })
                }
              }
            }
          }
      )
    },
    watch_dialog() {
      this.dialog_watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    }
  },
  computed: {
    ...mapGetters({
      cities: 'getCities',
    }),
  }
}
</script>
